import { LocationTypes } from '../constants';

import IconIconMapPinBranch from '../images/icon-map-pin-branch.png';
import IconMapPinCardlockPC from '../images/icon-map-pin-cardlock-pc.png';
import IconMapPinCardlockUm from '../images/icon-map-pin-cardlock-um.png';
import iconPinBoth from '../images/icon-map-pin-branch-cardlock.png';

export const DEFAULT_MAP_POSITION = {
  lat: 58,
  lng: -100,
};

const formatFrenchHours = (time, isEvening = false) => {
  const hours = time.split(':')[0];
  const minutes = time.split(':')[1];
  const hoursFormatted = isEvening ? +hours + 12 : hours;
  const minutesFormatted = minutes > 0 ? `${minutes}` : '';
  const timeFormatted = `${hoursFormatted}h${minutesFormatted}`;
  return timeFormatted;
};

const formatEnglishHours = (time, isEvening) => {
  const hours = time.split(':')[0];
  const minutes = time.split(':')[1];
  const timeFormatted = `${hours}:${minutes} ${isEvening ? 'PM' : 'AM'}`;
  return timeFormatted;
};

export const formatTodaysHours = (hours, locale) => {
  if (!hours) {
    return 'error formatting hours';
  }
  const closingHoursArray = hours.closing.split(':');
  closingHoursArray.pop();
  if (closingHoursArray.length === 0) {
    return locale.includes('en')
      ? 'Hours unavailable'
      : 'Heures non disponibles';
  }

  const openingHours = locale.includes('en')
    ? formatEnglishHours(hours.opening, false)
    : formatFrenchHours(hours.opening, false);
  let closingHours = locale.includes('en')
    ? formatEnglishHours(hours.closing, true)
    : formatFrenchHours(hours.closing, true);

  const closingHoursCheck = closingHoursArray.join(':');
  if (closingHoursCheck === '11:59') {
    closingHours = locale.includes('en') ? 'Midnight' : 'minuit';
  }

  return `${openingHours} - ${closingHours}`;
};

export const getMarkerIcon = (type) => {
  switch (type) {
    case LocationTypes.Branch:
      return IconIconMapPinBranch;
    case LocationTypes.UltramarCardlock:
      return IconMapPinCardlockUm;
    case LocationTypes.PipelineCardlock:
      return IconMapPinCardlockPC;
    default:
      return iconPinBoth;
  }
};

import { getDay } from 'date-fns';

export function getDayOfWeek() {
  const date = new Date();
  const day = getDay(date);
  const days = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];
  return days[day];
}

export function matchBrand(fuelBrand, brand) {
  let brandTitle;
  // matching to Contentful's brand title
  //TODO: get short text field made in contentful for MDM fuel brand
  switch (fuelBrand) {
    case 'FGO-Fas Gas':
    case 'FGP-Fas Gas Plus':
      brandTitle = 'Fas Gas Plus';
      break;
    case 'IOL-Esso':
    case 'Esso':
      brandTitle = 'Esso';
      break;
    case 'CHV-Chevron':
    case 'Chevron':
      brandTitle = 'Chevron';
      break;
    case 'ULT-Ultramar':
    case 'Ultramar':
      brandTitle = 'Ultramar';
      break;
    case 'SRG-SERGAZ':
      brandTitle = 'SERGAZ';
      break;
    case 'PNR-Pioneer':
      brandTitle = 'Pioneer';
      break;
    case 'CTND-Couche-Tard NON-DEALER':
      brandTitle = 'Couche Tard';
      break;
    case 'RTF-Race Trac':
      brandTitle = 'Race Trac';
      break;
    case 'SHE-Shell':
      brandTitle = 'Shell';
      break;
    case 'Pipeline':
    case 'PIP-Pipeline':
      brandTitle = 'Pipeline Commercial';
      break;
    case 'Columbia Fuels':
    case 'CF-Columbia Fuels':
      brandTitle = 'Columbia';
      break;
    case 'Bluewave':
    case 'BLU-Bluewave':
      brandTitle = 'Bluewave';
      break;
    case 'PCA-Petro Canada':
      brandTitle = 'Petro Canada';
      break;
    default:
      brandTitle = null;
  }

  if (brandTitle === brand.fields.brand) {
    return true;
  }
  return false;
}

// TODO: add to dictionary
export const locationProducts = [
  {
    enId: 'diesel',
    ref: 'HasDiesel_5',
    enValue: 'Diesel',
  },
  {
    enId: 'marked-diesel',
    ref: 'MarkedDiesel',
    enValue: 'Marked Diesel',
  },
  {
    enId: 'DEF',
    ref: 'Def',
    enValue: 'Diesel Exhaust Fluid',
  },
  {
    enId: 'gas',
    ref: 'Hasmarkedgas',
    enValue: 'Gas',
  },
];

export const getLocationProducts = (location) => {
  return locationProducts.filter((product) => {
    const ref = location[product.ref];
    return ref !== 'No Data';
  });
};

export const locationAmenities = [
  // {
  //   id: 'air',
  //   value: 'Air',
  //   valueFr: 'Air',
  //   ref: 'hasAir',
  // },
  // {
  //   id: 'atm',
  //   value: 'ATM',
  //   valueFr: 'Guichet automatique bancaire',
  //   ref: 'hasAtm',
  // },
  // {
  //   id: 'car-wash',
  //   value: 'Car Wash',
  //   valueFr: 'Lave-auto',
  //   ref: 'hasCarWash',
  // },
  {
    id: 'convenience-store',
    value: 'Convenience Store',
    valueFr: 'Dépanneurs',
    ref: 'HasCstore_5',
  },
  {
    id: 'lounge',
    value: 'Lounge',
    ref: 'Driverslounge',
  },
  {
    id: 'parking',
    value: 'Parking',
    valueFr: 'Stationnement',
    ref: 'Parking',
  },
  {
    id: 'restrooms',
    value: 'Restrooms',
    valueFr: 'Toilettes',
    ref: 'Restrooms',
  },
  {
    id: 'restaurant',
    value: 'Restaurant',
    valueFr: 'Restaurant',
    ref: 'Restaurant',
  },
  {
    id: 'shower',
    value: 'Shower',
    valueFr: 'Douche',
    ref: 'HasShower',
  },
  // {
  //   id: 'vacuum',
  //   value: 'Vacuum',
  //   valueFr: 'Vacuum',
  //   ref: 'hasVacuum',
  // },
  {
    id: 'wifi',
    value: 'Wifi',
    valueFr: 'Wifi',
    ref: 'WI_FI',
  },
];

export const locationTypes = [
  {
    id: 'all-locations',
    value: 'All Locations',
    valueFr: 'Toutes les stations',
  },
  {
    id: 'cardlock',
    value: 'Cardlock',
    valueFr: 'Cardlock',
  },
  {
    id: 'retail',
    value: 'Retail',
    valueFr: 'Vente au détail',
  },
];

export const retailFilters = [
  'Convenience Store',
  'Car Wash',
  'Restaurant',
  'Restroom',
  'Air',
  'Vacuum',
  'ATM',
  'Parking',
  '24 hour station',
];

export const cardLockFilters = [
  'Shower',
  'Restrooms',
  'Restaurant',
  'Parking',
  'Lounge',
  'Convenience Store',
  'Wifi',
];

export const checkLocationType = (typeId, location) => {
  if (typeId === 'all-locations') {
    return true;
  }
  if (typeId === 'cardlock') {
    return location.hasNfn;
  }
  if (typeId === 'retail') {
    return !location.hasNfn;
  }
};

export const filterLocation = (
  location,
  activeTypeId,
  activeFilterIds,
  activeProductFilters
) => {
  const isCorrectType = checkLocationType(activeTypeId, location);
  if (!isCorrectType) {
    return false;
  }
  let hasActiveAmenityFilters = true;
  const hasActiveProductFilters = true;
  if (activeFilterIds.length !== 0) {
    hasActiveAmenityFilters = activeFilterIds.every((filterId) =>
      location.filters.find((filter) => filter.id === filterId)
    );
  }
  if (activeProductFilters.length !== 0) {
    hasActiveAmenityFilters = activeProductFilters.every((filterId) => {
      return location[filterId] === 'YES';
    });
  }
  return hasActiveProductFilters && hasActiveAmenityFilters;
};

export const filterAmenityFilters = (filter, typeId) => {
  if (typeId === 'all-locations') {
    return true;
  }
  if (typeId === 'cardlock') {
    return cardLockFilters.find((f) => {
      const hasFilter = f === filter.value;
      return hasFilter;
    });
  }
  if (typeId === 'retail') {
    return retailFilters.find((f) => {
      const hasFilter = f === filter.value;
      return hasFilter;
    });
  }
};

export const getParsedHours = (hours) => {
  if (hours === '24 Hrs') {
    return hours;
  }
  const regex = /\((.*?)\)/;
  const match = hours.match(regex);
  if (match) {
    const parsedHours = match[1];
    return parsedHours;
  }
  return '';
};
