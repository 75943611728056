import React from 'react';
import { useTranslation } from 'react-i18next';
import { getParsedHours } from '../../../utils/map';

const LocationHours = ({ location }) => {
  const { t } = useTranslation();
  const hours = getParsedHours(location.Hours_of_Operation);
  return (
    <div>
      <h2 className="tw-text-lg tw-tracking-normal tw-uppercase tw-text-blue xl:tw-text-xl tw-my-0">
        {t('HOURS OF OPERATION')}
      </h2>
      <ul className="tw-mt-4 tw-grid tw-gap-x-2 tw-gap-y-1 tw-p-0 tw-font-light tw-text-base">
        <li className="tw-list-none">
          {location.Hours_of_Operation && t(hours)}
        </li>
      </ul>
    </div>
  );
};

export default LocationHours;
